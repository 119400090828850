<template>
  <div class="_bg-default _80w _100vh px-3">
    <div class="_full-w full_height d-flex justify-center mb-5 px-3">
      <MapPshycolog />
    </div>
    <v-row class="pa-3">
      <v-col cols="12" md="4">
        <v-card
          color="a__"
          class="radius-card d-flex"
          height="150px"
          @click="openDetail('bidang', 1)"
        >
          <section class="pa-3 white--text for__text" v-if="klinis !== null">
            <h1 class="__count">{{ klinis }}</h1>
            <h3>Psikologi Klinis</h3>
          </section>
          <section class="d-flex justify-end for__icon pa-3">
            <v-icon size="70" color="white">mdi-chart-arc</v-icon>
          </section>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card
          color="b__"
          class="radius-card d-flex"
          height="150px"
          @click="openDetail('bidang', 3)"
        >
          <section
            class="pa-3 white--text for__text"
            v-if="pendidikan !== null"
          >
            <h1 class="__count">{{ pendidikan }}</h1>
            <h3>Psikologi Pendidikan</h3>
          </section>
          <section class="d-flex justify-end for__icon [a-3">
            <v-icon size="70" color="white">mdi-chart-bubble</v-icon>
          </section>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card
          color="c__"
          class="radius-card d-flex"
          height="150px"
          @click="openDetail('bidang', 2)"
        >
          <section class="pa-3 white--text for__text" v-if="industri !== null">
            <h1 class="__count">{{ industri }}</h1>
            <h3>Psikologi Industri</h3>
          </section>
          <section class="d-flex justify-end for__icon pa-3">
            <v-icon size="70" color="white">mdi-chart-areaspline</v-icon>
          </section>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" v-if="psikologMan !== null">
        <v-card
          color="cyan"
          class="radius-card d-flex"
          height="150px"
          @click="openDetail('jenis_kelamin', 'laki-laki')"
        >
          <section class="pa-3 white--text for__text">
            <h1 class="__count">{{ psikologMan }}</h1>
            <h3>Male Psycholog</h3>
          </section>
          <section class="d-flex justify-end for__icon pa-3">
            <v-icon size="70" color="white">mdi-human-male</v-icon>
          </section>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" v-if="psikologWoman !== null">
        <v-card
          color="pink"
          class="radius-card d-flex"
          height="150px"
          @click="openDetail('jenis_kelamin', 'perempuan')"
        >
          <section class="pa-3 white--text for__text">
            <h1 class="__count">{{ psikologWoman }}</h1>
            <h3>Female Psycholog</h3>
          </section>
          <section class="d-flex justify-end for__icon pa-3">
            <v-icon size="70" color="white">mdi-human-female</v-icon>
          </section>
        </v-card>
      </v-col>
    </v-row>
    <div class="pa-3">
      <section class="mb-5">
        <h3 class="mb-n3">Stats</h3>
        <v-divider inset></v-divider>
      </section>
      <v-row class="pa-3">
        <v-col cols="12" md="6" v-if="artikel !== null">
          <v-card
            color="teal lighten-3"
            class="radius-card d-flex"
            height="150px"
            @click="openDetailLibrary('artikel')"
          >
            <section class="pa-3 white--text for__text">
              <h1 class="__count">{{ artikel }}</h1>
              <h3>Article</h3>
            </section>
            <section class="d-flex justify-end for__icon pa-3">
              <v-icon size="70" color="white">mdi-book-open-variant</v-icon>
            </section>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" v-if="survey !== null">
          <v-card
            color="cyan"
            class="radius-card d-flex"
            height="150px"
            @click="openDetailLibrary('survey')"
          >
            <section class="pa-3 white--text for__text">
              <h1 class="__count">{{ survey }}</h1>
              <h3>Survey</h3>
            </section>
            <section class="d-flex justify-end for__icon pa-3">
              <v-icon size="70" color="white">mdi-message-bookmark</v-icon>
            </section>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div class="pa-3">
      <section class="mb-5">
        <div class="py-6">
          <h3 class="mb-n3">Psycholog</h3>
          <v-divider inset></v-divider>
        </div>
        <v-row>
          <v-col
            cols="12"
            md="4"
            v-for="user in psikologProv"
            :key="user.nama_provinsi"
          >
            <v-card
              color="orange"
              class="radius-card d-flex"
              height="150px"
              @click="openDetail('provinsi', user.provinsi_id)"
            >
              <section class="pa-3 white--text for__text">
                <h1 class="__count">{{ user.total }}</h1>
                <h3>{{ user.nama_provinsi }}</h3>
              </section>
              <section class="d-flex justify-end for__icon pa-3">
                <v-icon size="70" color="white">mdi-diving-scuba-flag</v-icon>
              </section>
            </v-card>
          </v-col>
        </v-row>
      </section>
    </div>
    <!-- <div class="pa-3" v-if="activity">
      <section class="mb-5">
        <h3 class="mb-n3">Activity</h3>
        <v-divider inset></v-divider>
      </section>
      <v-row class="px-3">
        <v-col cols="12" md="2">type</v-col>
        <v-col cols="12" md="2">name</v-col>
        <v-col cols="12" md="2">date</v-col>
        <v-col cols="12" md="2">time</v-col>
        <v-col cols="12" md="4">activity</v-col>
      </v-row>
      <v-card
        class="radius-card my-3 pa-3"
        v-for="log in activity"
        :key="log.id"
      >
        <v-row>
          <v-col cols="12" md="2">
            <v-chip color="primary">
              {{ log.log_name }}
            </v-chip></v-col
          >
          <v-col cols="12" md="2">{{
            log.relationships[0].user.data.nama_lengkap
              ? log.relationships[0].user.data.nama_lengkap
              : "admin"
          }}</v-col>
          <v-col cols="12" md="2">{{
            $date(log.created_at).format("DD-MM-YYYY")
          }}</v-col>
          <v-col cols="12" md="2">{{
            $date(log.created_at).format("HH:ss")
          }}</v-col>
          <v-col cols="12" md="4">{{ log.description }}</v-col>
        </v-row>
      </v-card>
      <div v-if="!activity.length" class="d-flex justify-center">
        <section class="pa-2">
          <img src="../../assets/img/404.svg" height="200px" alt="404" />
          <h3 class="text-center">No Activity</h3>
        </section>
      </div>
      <footer class="pa-3 d-flex justify-center">
        <v-btn color="blue" dark v-if="activity.length" to="/admin/activity"
          >More</v-btn
        >
      </footer>
    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import MapPshycolog from "../General/mapPshycolog.vue";
export default {
  components: { MapPshycolog },
  name: "dashboardadmin",
  computed: {
    ...mapState({
      klinis: state => state.admin.klinis,
      pendidikan: state => state.admin.pendidikan,
      industri: state => state.admin.industri,
      activity: state => state.admin.activity,
      artikel: state => state.admin.artikel,
      survey: state => state.admin.survey,
      psikologMan: state => state.admin.psikologMan,
      psikologWoman: state => state.admin.psikologWoman,
      psikologProv: state => state.admin.psikologProv
    })
  },
  data() {
    return {
      currentPage: 1,
      limit: 5
    };
  },
  mounted() {
    this.$store.dispatch("admin/getSummary", 1);
    this.$store.dispatch("admin/getSummary", 2);
    this.$store.dispatch("admin/getSummary", 3);
    this.$store.dispatch("admin/getModelSummary", "Artikel");
    this.$store.dispatch("admin/getModelSummary", "Survey");
    this.$store.dispatch("admin/getUserSummary", {
      model: "User",
      child: "Laki-laki"
    });
    this.$store.dispatch("admin/getUserSummary", {
      model: "User",
      child: "Perempuan"
    });
    this.$store.dispatch("admin/getUserSummary", {
      model: "User",
      child: "Provinsi"
    });
    this.$store.dispatch("admin/log", {
      page: this.currentPage,
      limit: this.limit
    });
  },
  methods: {
    openDetail(type, value) {
      this.$router.push(`/admin/list-psikolog/${type}/${value}`);
    },
    openDetailLibrary(type) {
      if (type == "artikel") {
        this.$router.push(`/admin/library-management`);
      } else if (type == "survey") {
        this.$router.push(`/admin/survey-management`);
      }
    }
  }
};
</script>

<style scoped>
.__count {
  font-size: 40px;
  margin-top: 10px;
}
.for__text {
  width: 80%;
}
.for__icon {
  width: 20%;
}
.full_height {
  padding: 5px;
  height: 100vh;
}
</style>
